
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "add-association",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      entity: {
        entity_type_id: "",
        email: "",
        telephone: "",
        industry_sector: "",
        web_url: "",
        address: "",
        parent_entity_id: "",
        postcode: "",
        division: "",
        district: "",
        sub_district: "",
        entity_description: "",
        status: "",
        active_status: "1",
        status_remarks: "",
        fax: "",
        name: "",
        entity_short_name: "",
        registration_number: "",
        registration_date: "",
        registration_authority: "",
      },
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      loading: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getIndustry();
    await this.getEntityInfos();
    await this.getDivision();
    await this.getDistrictForEmp();
  },
  methods: {
    async formSubmit() {
      //let data = new FormData();
      let entity = {};
      Object.keys(this.entity).forEach((key) => {
        if (this.entity[key] != "") entity[key] = this.entity[key];
      });
      let data = {
        entity: entity,
      };
      this.loading = true;
      await ApiService.post("associatioin/new", data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              text: response.data.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              this.entity = {
                entity_type_id: "",
                email: "",
                telephone: "",
                industry_sector: "",
                web_url: "",
                address: "",
                parent_entity_id: "",
                postcode: "",
                division: "",
                district: "",
                sub_district: "",
                entity_description: "",
                status: "",
                active_status: "1",
                status_remarks: "",
                fax: "",
                name: "",
                entity_short_name: "",
                registration_number: "",
                registration_date: "",
                registration_authority: "",
              };
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getIndustry() {
      await ApiService.get("configurations/industry_sectors")
        .then((response) => {
          this.industry = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDivision() {
      await ApiService.get("geo/divisions")
        .then((response) => {
          this.divisions = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrict() {
      await ApiService.get("geo/districts?division=" + this.entity.division)
        .then((response) => {
          this.districts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrictForEmp() {
      await ApiService.get("geo/districts")
        .then((response) => {
          this.empDistricts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSubDistrict() {
      await ApiService.get(
        "geo/upazilas?division=" +
          this.entity.division +
          "&district=" +
          this.entity.district
      )
        .then((response) => {
          this.subDistricts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      web_url: Yup.string().required().label("Web Url"),
      postcode: Yup.string().required().max(4).label("Postcode"),
      registration_number: Yup.string().required().label("Registration Number"),
      registration_authority: Yup.string()
        .required()
        .label("Registration Authority"),
      telephone: Yup.number().required().min(11).label("Telephone"),
      entity_name: Yup.string().required().label("Name"),
      entity_short_name: Yup.string().required().label("Short Name"),

      employeeEmail: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label("Name"),
      designation: Yup.string().required().label("Designation"),
      gender: Yup.string().required().label("Gender"),
      mobile: Yup.number().required().min(11).label("Mobile"),
      dob: Yup.string().required().label("Date of Birth"),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password didn't match!")
        .label("Confirm Password"),
    });
    return {
      AssociationSchema,
    };
  },
});
